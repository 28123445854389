// extracted by mini-css-extract-plugin
export var ArtistDescription = "Zephyr-module--ArtistDescription--nvNNW";
export var ArtistInfos = "Zephyr-module--ArtistInfos--96qTH";
export var ButtonWrapper = "Zephyr-module--ButtonWrapper --TLaSH";
export var CardWrapper = "Zephyr-module--CardWrapper--Q63k3";
export var CarrouselWrapper2 = "Zephyr-module--CarrouselWrapper2--xOfLZ";
export var Citations = "Zephyr-module--Citations--kHY+3";
export var DescriptionWrapper = "Zephyr-module--DescriptionWrapper--gGGiA";
export var ImageWrapper = "Zephyr-module--ImageWrapper--dTq3j";
export var LinkWrapper = "Zephyr-module--LinkWrapper--RXGgy";
export var MobileProtrait = "Zephyr-module--MobileProtrait--BLLGS";
export var More = "Zephyr-module--More--Ogbth";
export var MoreButton = "Zephyr-module--MoreButton--HrmvQ";
export var NameWrapper = "Zephyr-module--NameWrapper--dSUJQ";
export var PdpWrapper = "Zephyr-module--PdpWrapper--qZPFt";
export var PhotosWrapper = "Zephyr-module--PhotosWrapper--adJ+9";
export var ProfilWrapper = "Zephyr-module--ProfilWrapper--XZ0ON";
export var TitleWrapper = "Zephyr-module--TitleWrapper--s5MUZ";
export var Wrapper = "Zephyr-module--Wrapper--W0jYS";